import {
  IGetContactLogDetails,
  IUpdateContactLogBodyWithParam,
} from './../../types/contactLogsTypes';
// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { ContactLogsAPI } from 'api/endpoints';
// Interfaces and types
import { IResMessage, TQueryParams } from 'types/appTypes';
import { IErrorRes } from 'types/appTypes';
import {
  ICreateContactLogBody,
  ICreateContactLogRes,
  IGetContactLogsRes,
} from 'types/contactLogsTypes';

const getContactLogsAsync = createAsyncThunk<
  IGetContactLogsRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>('contactLogs/getContactLogs', async (params: TQueryParams, { rejectWithValue }) => {
  try {
    return await ContactLogsAPI.getContactLogs(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const createContactLogAsync = createAsyncThunk<
  ICreateContactLogRes,
  ICreateContactLogBody,
  { rejectValue: IErrorRes }
>('contactLogs/createContactLog', async (cred: ICreateContactLogBody, { rejectWithValue }) => {
  try {
    return await ContactLogsAPI.createContactLog(cred);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getContactLogDetailsAsync = createAsyncThunk<
  IGetContactLogDetails,
  number,
  { rejectValue: IErrorRes }
>('contactLogs/getContactLogDetailsAsync', async (cred: number, { rejectWithValue }) => {
  try {
    return await ContactLogsAPI.getContactLogDetails(cred);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const updateContactLogAsync = createAsyncThunk<
  IResMessage,
  IUpdateContactLogBodyWithParam,
  { rejectValue: IErrorRes }
>('contactLogs/update', async (cred: IUpdateContactLogBodyWithParam, { rejectWithValue }) => {
  try {
    return await ContactLogsAPI.updateContactLog(cred);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const deleteContactLogAsync = createAsyncThunk<
  IResMessage,
  number,
  { rejectValue: IErrorRes }
>('contactLogs/delete', async (params: number, { rejectWithValue }) => {
  try {
    return await ContactLogsAPI.deleteContactLog(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

export {
  getContactLogsAsync,
  createContactLogAsync,
  updateContactLogAsync,
  getContactLogDetailsAsync,
  deleteContactLogAsync,
};
