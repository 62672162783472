// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import {
  IGetContactsRes,
  ICreateContactBody,
  ICreateContactRes,
  IUpdateContactBodyWithParam,
  IUpdateContactRes,
  IGetContactParams,
  IGetContactRes,
  IDeleteContactParams,
  IDeleteContactRes,
} from 'types/contactTypes';
import { IGetCompaniesRes } from 'types/companyTypes';
import { TQueryParams } from 'types/appTypes';

export const getContacts = (params: TQueryParams) => {
  return xhr.get<IGetContactsRes, IGetContactsRes>(`${ENDPOINTS.contacts}`, {
    params,
  });
};

export const createContact = ({ body }: ICreateContactBody) => {
  return xhr.post<ICreateContactRes, ICreateContactRes>(`${ENDPOINTS.contacts}`, body);
};

export const getContact = ({ contactId }: IGetContactParams) => {
  return xhr.get<IGetContactRes, IGetContactRes>(`${ENDPOINTS.contacts}/${contactId}`);
};

export const updateContact = ({ contactId, body }: IUpdateContactBodyWithParam) => {
  return xhr.put<IUpdateContactRes, IUpdateContactRes>(
    `${ENDPOINTS.contacts}/${contactId}`,
    body,
  );
};

export const deleteContact = ({ contactId }: IDeleteContactParams) => {
  return xhr.delete<IDeleteContactRes, IDeleteContactRes>(
    `${ENDPOINTS.contacts}/${contactId}`,
  );
};

export const getCompaniesForContactsSelect = () => {
  return xhr.get<IGetCompaniesRes, IGetCompaniesRes>(
    `${ENDPOINTS.companies}?columns=id&columns=accountName&columns=name&sortColumn=name&sortOrder=ASC`,
  );
};
