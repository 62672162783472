// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  getContactLogsAsync,
  getContactLogDetailsAsync,
  updateContactLogAsync,
  deleteContactLogAsync,
  createContactLogAsync,
} from './contactLogsThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IErrorRes } from 'types/appTypes';
import { IGetContactLogsRes, IGetContactLogDetails } from 'types/contactLogsTypes';

interface ISliceContactLogsState {
  contactLogs: IGetContactLogsRes | null;
  contactLogDetails: IGetContactLogDetails | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: ISliceContactLogsState = {
  contactLogs: null,
  contactLogDetails: null,
  error: null,
  isLoading: false,
};

const contactLogsSlice = createSlice({
  name: 'contactLogs',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    //* GET CONTACT LOGS ASYNC THUNK
    builder.addCase(getContactLogsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getContactLogsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.contactLogs = payload;
    });
    builder.addCase(getContactLogsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CREATE CONTACT ASYNC THUNK
    builder.addCase(createContactLogAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(createContactLogAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(createContactLogAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* GET CONTACT LOG DETAILS ASYNC THUNK
    builder.addCase(getContactLogDetailsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getContactLogDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.contactLogDetails = payload;
    });
    builder.addCase(getContactLogDetailsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* UPDATE CONTACT LOG (one) ASYNC THUNK
    builder.addCase(updateContactLogAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(updateContactLogAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(updateContactLogAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* DELETE CONTACT LOG ASYNC THUNK
    builder.addCase(deleteContactLogAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(deleteContactLogAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteContactLogAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default contactLogsSlice;
